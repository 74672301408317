/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import 'variables';
@import 'bootstrap/scss/_mixins';
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/forms';

.ant-steps-small .ant-steps-item-icon {
  margin: 0 12px 0 0;
}

.ant-steps-small .ant-steps-item-title {
  margin-top: 1px;
  color: $neutral-700 !important;
  font-size: 13px;
  font-weight: 300;
  line-height: 160%;
  @media only screen and (min-width: $medium-device-breakpoint) {
    font-size: 15px;
  }
}

.ant-steps-item-content,
.ant-steps-item-icon {
  height: 21px;
}

.color-text-text {
  color: $text-text;
}

.scrollable-area {
  max-height: 30rem;
  overflow-y: auto;
}

.modal-dialog {
  top: 5rem !important;
  padding: 0 !important;
  margin: 0 auto !important;
  vertical-align: baseline;
  @media screen and (max-width: $medium-device-breakpoint) {
    top: 3rem !important;
  }
}

.ant-popover-arrow {
  display: none;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 0;
  padding-right: 1rem;
}

.modal-wrap {
  padding-left: 340px;
  @media only screen and (max-width: calc($large-device-breakpoint - 1px)) {
    padding-left: 0;
  }
}

label[nz-radio] {
  font-size: 18px;
  @media screen and (max-width: $small-device-breakpoint) {
    font-size: 16px;
  }
}

.multi-select {
  cdk-virtual-scroll-viewport {
    height: 110px !important;
  }

  .ant-select-item,
  .ant-select-item-empty {
    padding: 8px 12px;
    font-size: 18px;
    font-weight: 400;
    @media screen and (max-width: $small-device-breakpoint) {
      font-size: 16px;
    }
    line-height: 28.8px;
  }

  .ant-select-dropdown {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ant-select-dropdown {
    padding: 0;
  }

  .ant-select-item:first-child {
    margin-top: 8px;
  }

  .ant-select-item:last-child {
    margin-bottom: 8px;
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $secondary-100;
}

.hidden {
  display: none;
}

.ant-popover-buttons {
  display: none;
}

.ant-popover-message {
  display: inline-flex;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}

.ant-message-custom-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pac-container {
  background-color: $white;
  position: absolute !important;
  z-index: 1000;
  border-radius: 8px;
  border-top: none;
  font-family: Outfit, sans-serif;
  -webkit-box-shadow: 0px 0px 25px 0px var(--Inputs-Dropdpwn-Shadows, rgba(138, 142, 148, 0.25));
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0px 0px 25px 0px var(--Inputs-Dropdpwn-Shadows, rgba(138, 142, 148, 0.25));
}

.pac-logo:after {
  content: '';
  padding: 0;
  height: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: right;
  display: block;
  background-image: none;
  background-position: right;
  background-repeat: no-repeat;
  -webkit-background-size: 0;
  background-size: 0;
}

.pac-item {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  border-top: none;
  color: $neutral-800;
  border-radius: 4px;
}

.pac-item:hover {
  background-color: $secondary-100;
}

.pac-item-query {
  font-size: 18px;
  font-weight: 400;
}

.pac-icon {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  font-family: 'Outfit';
}

* {
  font-family: 'Outfit';
}

.linkify {
  text-decoration: underline !important;
  word-break: break-all;
}

.unigana-link {
  text-decoration: none;
}

.ant-switch {
  background-color: $neutral-100;
}

.ant-switch-checked {
  background-color: $secondary-500;
}

.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
}

.lang-switch {
  .selected {
    background: $brand-100;
  }

  &-button {
    width: 36px;
    height: 32px;
    padding: 9px 4px;
    cursor: pointer;
    background: $neutral-50;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media screen and (max-width: $small-device-breakpoint) {
      width: 28px;
      height: 24px;
      font-size: 11px;
      padding: 9px 6px;
    }
  }

  &-button:nth-child(1) {
    border-radius: 4px 0 0 4px;
    @media screen and (max-width: $small-device-breakpoint) {
      border-radius: 2px 0 0 2px;
    }
  }

  &-button:nth-child(2) {
    border-radius: 0px 4px 4px 0px;
    @media screen and (max-width: $small-device-breakpoint) {
      border-radius: 0px 2px 2px 0px;
    }
  }
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 8px;
}

.ant-upload.ant-upload-select-picture-card {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 8px;
}

.ant-mentions-dropdown-menu-item:first-child,
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 8px;
}

.ant-table-container table > thead > tr:first-child th:first-child,
.ant-table-container table > thead > tr:last-child th:last-child {
  border-radius: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 12px 16px;
}

.ant-mentions-dropdown-menu-item {
  border-radius: 8px;
}

.ant-breadcrumb {
  font-size: inherit;
  line-height: inherit;
}

.ant-popover-content {
  border-radius: 8px;
}

.ant-input-affix-wrapper {
  background-color: $white;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-popover-arrow-content::before {
  background: rgba(255, 255, 255, 0);
}

.ant-popover-arrow-content {
  background: rgba(255, 255, 255, 0);
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
  margin: 0;
  letter-spacing: 0;
}

.ant-popover-inner-content {
  padding: 0;
}

.ant-badge-count {
  min-width: 25px;
}

.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  transform: translate(60%, -50%);
}

.ant-popover-inner-content,
.ant-popover-inner {
  border-radius: 8px;
}

nz-form-item nz-input-group {
  border-radius: 8px;
  font-size: 16px;
}

nz-form-item nz-input-group input {
  border-radius: 8px;
  padding: 6px;
  font-size: 16px;
}

nz-date-picker {
  border-radius: 8px;
}

.error-text {
  color: $error-600;
}

::ng-deep .ant-btn[disabled] {
  pointer-events: none;
}

/* styles for checkboxes */
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $success-500 !important;
}

:host ::ng-deep .ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $success-500 !important;
}

:host ::ng-deep ::selection {
  background: $success-500;
}

:host ::ng-deep .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $success-500;
}

:host ::ng-deep html {
  --antd-wave-shadow-color: $success-500;
}

:host ::ng-deep .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $success-500;
  border-color: $success-500;
}

:host ::ng-deep .ant-checkbox-inner {
  border-radius: 4px;
}

:host ::ng-deep .ant-checkbox-checked::after {
  border: 1px solid $success-500;
  border-radius: 4px;
}

:host ::ng-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

a {
  color: inherit;
}

.ant-select-item {
  border-radius: 6px;
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  padding: 0 4px;
}

.ant-select-item-empty {
  display: none;
}

.ant-tooltip-inner {
  border-radius: 4px;
  background-color: $neutral-800;
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 5px;
}

::ng-deep .ant-message-notice-content {
  border-radius: 8px;
  padding: 8px 16px;
}

/* ---------------------------- */

/* styles for modals */
.ant-modal-header {
  border-radius: 8px 8px 0 0;
  border-bottom: none;
  padding: 0;
}

.ant-modal-body {
  padding: 0 24px 32px 24px;
  @media only screen and (max-width: $small-device-breakpoint) {
    padding: 0 16px 32px 16px;
  }
}

.ant-modal-footer {
  padding: 0 24px 32px 24px;
  @media only screen and (max-width: $small-device-breakpoint) {
    padding: 0 16px 24px 16px;
  }
  border-top: none;
  border-radius: 0 0 8px 8px;
}

.ant-modal-content {
  border-radius: 8px;
  padding: 24px 0 0 0;
  @media only screen and (max-width: $small-device-breakpoint) {
    padding: 20px 0 0 0;
  }
}

/* styles for ant-steps */
:host ::ng-deep .ant-steps-navigation .ant-steps-item::after {
  display: none;
}

:host ::ng-deep .ant-steps-navigation .ant-steps-item::before {
  left: 0;
  width: 100%;
}

:host ::ng-deep .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: $neutral-900;
}

:host
  ::ng-deep
  .ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon {
  border: none;
}

:host
  ::ng-deep
  .ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-description {
  color: inherit;
}

:host
  ::ng-deep
  .ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: $white;
}

/* finish */
:host ::ng-deep .ant-steps-navigation .ant-steps-item-finish::before {
  background-color: $success-600;
}

:host ::ng-deep .ant-steps-item-finish .ant-steps-item-icon {
  background-color: $success-600;
  border: none;
}

:host ::ng-deep .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $white;
}

/* process */
:host ::ng-deep .ant-steps-navigation .ant-steps-item-process::before {
  background-color: $brand-500;
}

:host ::ng-deep .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: $brand-500;
}

/* wait */
:host ::ng-deep .ant-steps-navigation .ant-steps-item-wait::before {
  background-color: $neutral-600;
}

:host ::ng-deep .ant-steps-item-wait .ant-steps-item-icon {
  background-color: $neutral-600;
  border: none;
}

:host ::ng-deep .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: $white;
}

::ng-deep .list-actions-sort {
  .ant-radio-wrapper {
    font-size: 18px;
    font-weight: 300;
    @media screen and (max-width: $small-device-breakpoint) {
      font-size: 14px;
    }
  }
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 0s 600000s,
    color 0s 600000s !important;
}

.color-red-600 {
  color: $error-600;
}
