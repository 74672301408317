$white: #fcfcfc;
$black: #000;
$white-shades: #e2e2e2;
$grey: #d9d9d9;

$text-name: #2c3035;
$text-text: #505760;
$inputs-unactive-stroke: #737980;
$inputs-active-label: #222528;

// special users color
//ganatrons
$purple-50: #f4f1ff;
$purple-100: #dcd3ff;
$purple-200: #cbbdff;
$purple-300: #b39fff;
$purple-400: #a48dff;
$purple-500: #8d70ff;
$purple-600: #8066e8;
$purple-700: #6450b5;
$purple-800: #4e3e8c;
$purple-900: #3b2f6b;

//Ganaquipos
$bushes-2-50: #f2f7ed;
$bushes-2-100: #d7e7c7;
$bushes-2-200: #c4dcab;
$bushes-2-300: #a9cc85;
$bushes-2-400: #98c26d;
$bushes-2-500: #7eb349;
$bushes-2-600: #73a342;
$bushes-2-700: #597f34;
$bushes-2-800: #456228;
$bushes-2-900: #354b1f;

//Ganagentes
$trunk-3-50: #f9f3ed;
$trunk-3-100: #edd9c6;
$trunk-3-200: #e4c6aa;
$trunk-3-300: #d7ac83;
$trunk-3-400: #d09c6b;
$trunk-3-500: #c48346;
$trunk-3-600: #b27740;
$trunk-3-700: #8b5d32;
$trunk-3-800: #6c4827;
$trunk-3-900: #52371d;

//Ganasocios
$brand-50: #e6f2f1;
$brand-100: #b0d7d3;
$brand-200: #8ac4bd;
$brand-300: #55a99f;
$brand-400: #34998d;
$brand-500: #017f70;
$brand-600: #017466;
$brand-700: #015a50;
$brand-800: #01463e;
$brand-900: #00352f;

//Ganaliados :
$bush-6-50: #f6e9ef;
$bush-6-100: #e2bbce;
$bush-6-200: #d49bb6;
$bush-6-300: #c16d95;
$bush-6-400: #b55181;
$bush-6-500: #a22561;
$bush-6-600: #932258;
$bush-6-700: #731a45;
$bush-6-800: #591435;
$bush-6-900: #441029;

$secondary-50: #e6f2fa;
$secondary-100: #b0d7ef;
$secondary-200: #8ac3e7;
$secondary-300: #54a8dc;
$secondary-400: #3397d5;
$secondary-500: #007dcb;
$secondary-600: #0072b9;
$secondary-700: #005990;
$secondary-800: #004570;
$secondary-900: #003555;

$success-50: #b5ffd3;
$success-100: #73ffab;
$success-200: #32e479;
$success-300: #24c665;
$success-400: #16ab52;
$success-500: #0d9443;
$success-600: #078339;
$success-700: #03712f;
$success-800: #025e27;
$success-900: #00401a;

$warning-50: #fdf0e6;
$warning-100: #facfb0;
$warning-200: #f8b88a;
$warning-300: #f49755;
$warning-400: #f28334;
$warning-500: #ef6401;
$warning-600: #d95b01;
$warning-700: #aa4701;
$warning-800: #833701;
$warning-900: #642a00;

$error-50: #fce9e9;
$error-100: #f5bbbb;
$error-200: #f09b9b;
$error-300: #ea6d6d;
$error-400: #e55151;
$error-500: #df2525;
$error-600: #cb2222;
$error-700: #9e1a1a;
$error-800: #7b1414;
$error-900: #5e1010;

$neutral-50: #eeeeef;
$neutral-100: #c9cbce;
$neutral-200: #afb2b6;
$neutral-300: #8a8e94;
$neutral-400: #737980;
$neutral-500: #505760;
$neutral-600: #494f57;
$neutral-700: #393e44;
$neutral-800: #2c3035;
$neutral-900: #222528;

$xsmall-device-breakpoint: 30em;
$small-device-breakpoint: 40em; // Small devices such as large phones (640px and up)
$medium-device-breakpoint: 48em; // Medium devices such as tablets (768px and up)
$large-device-breakpoint: 64em; // Large devices such as laptops (1024px and up)
$xlarge-device-breakpoint: 80em; // Largest devices such as desktops (1280px and up)

/* Common */
.outfit {
  font-family: 'Outfit', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.jost {
  font-family: 'Jost', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.extra-bold {
  font-weight: 800;
}
.bold {
  font-weight: 700;
}
.medium {
  font-weight: 500;
}
.regular {
  font-weight: 300;
}
.light {
  font-weight: 300;
}

.underlined {
  text-decoration: underline;
}
.heading-2xlarge {
  font-size: 2.986rem;
  line-height: 120%;
  @media screen and (max-width: $medium-device-breakpoint) {
  }
}

.heading-xlarge {
  font-size: 3.375rem;
  line-height: 120%;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 2.5rem;
    line-height: 140%;
  }
}

.heading-large {
  font-size: 2.8125rem;
  line-height: 140%;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 2.5rem;
    line-height: 140%;
  }
}

.heading-medium {
  font-size: 37px;
  line-height: 140%;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 28px;
    line-height: 140%;
  }
}
.heading-semi-medium {
  font-size: 31px;
  line-height: 140%;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 23px;
    line-height: 160%;
  }
}

.heading-semi-medium {
  font-size: 31px;
  line-height: 140%;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 23px;
    line-height: 160%;
  }
}
.heading-small {
  font-size: 26px;
  line-height: 160%;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 19px;
    line-height: 160%;
  }
}

.paragraph-large {
  font-size: 22px;
  line-height: 160%;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 19px;
    line-height: 160%;
  }
}

.paragraph-medium {
  font-size: 18px;
  line-height: 28.8px;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 16px;
    line-height: 25.6px;
  }
}

.paragraph-small-text {
  font-size: 18px;
  line-height: 160%;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 15px;
    line-height: 160%;
  }
  @media only screen and (max-width: $small-device-breakpoint) {
    font-size: 13px;
    line-height: 160%;
  }
}

.paragraph-small-card {
  font-size: 15px;
  line-height: 160%;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 13px;
    line-height: 160%;
  }
}

.paragraph-small-text {
  font-size: 18px;
  line-height: 160%;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 15px;
    line-height: 160%;
  }
  @media only screen and (max-width: $small-device-breakpoint) {
    font-size: 13px;
    line-height: 160%;
  }
}

.paragraph-small {
  font-size: 13px;
  line-height: 18pt;
  @media only screen and (max-width: $medium-device-breakpoint) {
    font-size: 10px;
    line-height: 14pt;
  }
}
